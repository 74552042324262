import React from "react";

export function Pentagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg2"
      version="1.1"
      viewBox="0 0 213.18 214.02"
      className="App-logo"
    >
      <g id="layer1" transform="translate(-207.27 -303.2)">
        <path
          id="path3350"
          fill="#000"
          d="M313.39 515.41c-9.17-14.199-29.879-5.695-42.088-16.379-22.321-10.704-40.189-30.204-49.168-53.247-16.599-6.872-1.069 18.522 3.592 23.94 19.284 28.199 53.224 47.959 87.614 46.228l.05-.543z"
        ></path>
        <path
          id="path3348"
          fill="#000"
          d="M339.36 513.48c35.218-8.266 64.808-36.012 76.285-70.19-16.24-1.25-13.775 22.359-25.57 29.515-17.247 20.831-43.798 34.667-70.548 35.536-11.214 16.231 13.718 5.263 19.833 5.14z"
        ></path>
        <path
          id="path3346"
          fill="maroon"
          d="M334.77 444.26c-11.874-1.163-9.81 17.253-14.586 25.68l-6.042 18.57c-9.185-27.922-18.165-55.91-27.462-83.795-10.089 13.549 6.997 27.981 7.264 42.405 6.624 20.299 13.294 40.584 19.897 60.89 7.447-21.092 14.387-42.361 20.929-63.75z"
        ></path>
        <path
          id="path3344"
          fill="#000"
          d="M215.88 440.29c4.535-17.235-5.111-35.149 1.92-52.249 4.74-20.804 16.372-40.109 33.046-53.506 2.783-13.599 22.704-10.544 33.166-18.764 27.435-8.692 58.945-4.91 83.045 11.065 14.498-3.281 13.92 17.958 26.193 24.335 18.765 23.896 23.888 57.316 15.616 86.335 16.274 4.527 9.825-23.106 11.582-33.17-1.373-54.902-51.831-102.87-106.81-101.09-46.557-.096-91.324 32.924-102.39 78.668-4.54 18.475-7.001 45.616 2.773 59.41l1.858-1.034z"
        ></path>
        <path
          id="path3342"
          fill="maroon"
          d="M288.79 440.09c-4.835-11.769-21.056-4.23-31.543-6.087h-17.729c23.223-17.193 47.14-33.464 69.732-51.499-14.994-4.805-23.565 15.312-36.845 19.932-16.99 12.297-33.941 24.647-50.991 36.86 19.262 3.338 38.991.789 58.444 1.448 2.983-.132 5.963-.339 8.931-.655z"
        ></path>
        <path
          id="path3340"
          fill="maroon"
          d="M407.79 440.61c-17.892-13.683-36.295-26.675-54.554-39.859-5.398 10.869 13.141 14.783 19.65 21.882l14.983 10.871c-28.141.383-56.301-.203-84.427 1.055 1.26 13.642 26.699 3.17 37.261 6.428 22.363.052 44.727.013 67.087-.378z"
        ></path>
        <path
          id="path3338"
          fill="maroon"
          d="M355.48 381.51c4.069-15.41 14.686-39.401 14.522-49.531-18.219 12.241-35.645 25.6-53.454 38.419 15.46 5.684 23.884-14.517 36.882-19.164 11.484-.951-4.094 20.42-3.869 28.334-5.608 17.34-11.351 34.636-16.774 52.036 12.555-.81 10.452-18.39 15.384-27.629l7.309-22.465z"
        ></path>
        <path
          id="path3293"
          fill="maroon"
          d="M341.63 396.03c-5.517-12.962-21.487-16.289-30.79-26.147-18.324-13.344-36.647-26.688-54.991-40.004 7.215 21.606 13.903 43.386 21.384 64.904 10.515-12.357-6.905-27.181-7.169-40.887 0-12.475 16.725 9.164 23.498 11.073 15.636 11.328 31.182 22.778 46.879 34.022.449-.966.837-1.955 1.19-2.96z"
        ></path>
      </g>
    </svg>
  );
}