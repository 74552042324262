/*jshint esversion: 6 */
import { Pentagram } from './img/svg/Pentagram.js';
import './App.css';
import { HumanCalendar } from './calendars/HumanCalendar';
import { DemonCalendar } from './calendars/DemonCalendar';
import { DdMmYyyy } from './calendars/DdMmYyyy.js';
import React from 'react';

function App() {
  /*let calendarH = new HumanCalendar(0, 1, 2, 6, 0);
  let calendarD = new DemonCalendar();
  calendarD.equalizeToParallel(calendarH);
  console.log("Human calendar : ".concat(calendarH.toDDMMYYYY().toString()));
  console.log("Demon calendar after equalization : ".concat(calendarD.toDDMMYYYY().toString()));*/
  return (
    <div className="App">
      <header className="App-header">
        <h6>
          Website still in construction...
        </h6>
        {Pentagram()}
        <p>
          Enter the date {'\u2764'}
        </p>
        <DateForm/>
      </header>
    </div>
  );
}

class DateForm extends React.Component {
  constructor(){
    super();
    
      this.state = {
        placeHolder : "06/06/1999",
        error : true,
        date : new DdMmYyyy()
      }; 
    
    this.parse = this.parse.bind(this);
  }

  parse(event){
    //console.log(event.target.value);
    var tmpDate = DdMmYyyy.fromString(event.target.value);
    if(tmpDate === -1){
      this.setState({error : true});
    }else{
      this.setState({error : false});
      this.setState({date : tmpDate});
    }
  }
  
  result(){
    if(this.state.error){
      return(<p>Date format must be DD/MM/YYYY...</p>);
    }
    var human = new HumanCalendar().fromDDMMYYYY(this.state.date);
    var demon = new DemonCalendar();
    demon.equalizeToParallel(human);
    return(<p>{demon.toDDMMYYYY().toString()}</p>);  
  }

  input(){
    return(
    <form>
      <input type={Text.toString()} placeholder={this.state.placeHolder} onChange={this.parse}/>
    </form>);
  }

  render(){
    return(<div>{this.input()}{this.result()}</div>);
  }
}

export default App;
