/*jshint esversion: 6 */
import { DdMmYyyy } from "./DdMmYyyy";

/** 
 * @abstract
 * @constructor
 * @class Calendar
*/
export class Calendar {
    //To be defined in the extending class
    //All but daysAddedEachMonth and leapYearModif are defined as simple numbers
    //daysAddedEachMonth is an array of number of days added each month, the index is the month to which the days are added
    //leapYearModif is an array of leapPairs
    //hoursInDay;
    //daysInWeek;
    //weeksInMonth;
    //monthsInYear;
    //daysAddedEachMonth = [];
    //leapYearModif = [];

    //Current date of the calendar
    //currentHour; //of the day
    //currentDay; //of the week
    //currentWeek; //of the month
    //currentMonth; //of the year
    //currentYear; //since some random event

    constructor(currentHour=0, currentDay=1, currentWeek=1, currentMonth=1, currentYear=1) {
        if(this.constructor == Calendar){
            throw new Error("Abstart classes can't be initiated !");
        }
        this.currentHour = currentHour;
        this.currentDay = currentDay;
        this.currentWeek = currentWeek;
        this.currentMonth = currentMonth;
        this.currentYear = currentYear;
    }
    
    //if the input is incorrect, meaning that it doesnt respect the calendar format, we can still rationalize it
    rationalize(){
        let carryOver = 0;
        let newValue = 0;

        if(this.currentHour > this.hoursInDay){
            carryOver = Math.floor(this.currentHour / this.hoursInDay);
            newValue =  this.currentHour % this.hoursInDay;
            this.currentHour = 0 >= newValue ? 1 : newValue;
            this.currentDay += carryOver;
        }
        
        if(this.currentDay > this.daysInWeek){
            carryOver = Math.floor(this.currentDay / this.daysInWeek);
            newValue =  this.currentDay % this.daysInWeek;
            this.currentDay = 0 >= newValue ? 1 : newValue;
            this.currentWeek += carryOver;
        }
        
        let addedDays = this.getAddedDaysForCurrentMonthAndYear();
        if(this.currentDay + (this.currentWeek -1) * this.daysInWeek > addedDays + this.weeksInMonth * this.daysInWeek){
            carryOver = Math.floor(this.currentWeek / this.weeksInMonth);
            newValue = this.currentWeek % this.weeksInMonth;
            this.currentWeek = 0 >= newValue ? 1 : newValue;
            this.currentMonth += carryOver;
        }

        if(this.currentMonth > this.monthsInYear){
            carryOver = Math.floor(this.currentMonth / this.monthsInYear);
            newValue = this.currentMonth % this.monthsInYear;
            this.currentMonth = 0 >= newValue ? 1 : newValue;
            this.currentYear += carryOver;
        }
        
    }

    //TODO
    toDDMMYYYY(){
        return new DdMmYyyy(this.currentDay + (this.currentWeek - 1) * this.daysInWeek, this.currentMonth, this.currentYear);
    }

    setFromDDMMYYYY(date){
        this.currentDay = date.day;
        this.currentMonth = date.month;
        this.currentYear = date.year;
        this.rationalize();
    }

    fromDDMMYYYY(date){
        return new this.constructor(1, 
        date.day,
        1,
        date.month,
        date.year);
    }

    /**
     * @abstract
     */
    isLeapYear(){
        throw new Error("Abstract method can't be called !");
    }

    isLeapMonth(){
        this.leapYearModif.forEach(e => {
            if(e.month === this.currentMonth){
                return true;
            }
        });
        return false;
    }

    getAddedDaysForCurrentMonthAndYear(){
        let res = this.daysAddedEachMonth[this.currentMonth-1];
        if(this.isLeapYear() && this.isLeapMonth()){
            this.leapYearModif.forEach(e => {
                if(e.month === this.currentMonth){
                    return res += e.daysAdded;
                }
            });
        }
        if(res === -27 || res === -26){
            console.log("ok");
        }
        return res;
    }

    //TODO for the hours too
    equalizeToParallel(calendar){
        let dayOfTheYear = 0;
        for(let i = 0; i < calendar.currentMonth - 1; ++i){
            dayOfTheYear += calendar.daysAddedEachMonth[i] + calendar.daysInWeek * calendar.weeksInMonth;
        }
        dayOfTheYear += calendar.currentDay + (calendar.currentWeek - 1) * calendar.daysInWeek;
        this.reinitialize();
        this.currentDay = dayOfTheYear;
        this.currentYear = calendar.currentYear;
        this.rationalize();
    }

    reinitialize(){
        this.currentHour = 0;
        this.currentDay = 1;
        this.currentWeek = 1;
        this.currentMonth = 1;
        this.currentYear = 1    ;
    }
}

/**
 * @class LeapPairs
 * @constructor
 * This class is used to reprenst pairs of months and days added to this month in the case of a leap year.
 * month represents the number of the month in the year : November = 11
 */
export class LeapPairs{
    //month;
    //daysAdded; //or substracted
    constructor(month, daysAdded){
        this.month = month;
        this.daysAdded = daysAdded;
    }
}