/**
 * @constructor
 * @class DdMmYyyy
 */
export class DdMmYyyy {
    day;
    month;
    year;
    stringFormat;
    constructor(day=0, month=0, year=0){
        this.day = day;
        this.month = month;
        this.year = year;
    }
    
    static fromString(string){
        var size = string.length;
        if(size < 5){
            return -1;
        }

        var split = string.split("/");

        if(split.length !== 3){
            return -1;
        }

        split.forEach(element => {
            if(!isNaN(Number(element))){
                return -1;
            }
        });

        return new DdMmYyyy(Number(split[0]), Number(split[1]), Number(split[2]));
    }

    toString(){
        let result = "";

        if(this.day < 10){
            result = result.concat("0".concat(this.day));
        }else{
            result = result.concat(this.day);
        }

        result = result.concat("/");

        if(this.month < 10){
            result = result.concat("0".concat(this.month));
        }else{
            result = result.concat(this.month);
        }

        result = result.concat("/".concat(this.year));

        return result;
    }
}